import React from 'react'
import Layout from "../components/App/Layout"
import NavbarStaging from "../components/App/NavbarStaging"
import Footer from "../components/App/Footer"
import StagingProcess from '../components/History/StagingProcess'

const History = () => {
    return (
        <Layout>
            <NavbarStaging />
            <StagingProcess />
            <Footer />
        </Layout>
    );
}

export default History