import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import history1 from '../../assets/images/history/history1.jpg'
import history2 from '../../assets/images/history/history2.jpg'
import StagingAnalytics from '../../assets/images/history/StagingAnalytics.jpg'
import StagingSetup from '../../assets/images/history/StagingSetup.jpg'
import history4 from '../../assets/images/history/StagingRemoval.jpg'

const StagingProcess = () => {
    return (
        <section className="history-area ptb-100 bg-fafafb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                    </span>
                    <h2>The Staging Process</h2>
                </div>

                <ol className="timeline history-timeline">
                    <li className="timeline-block">
                        <div className="timeline-date">
                            <span>Step</span>
                            1
                            <sup>st</sup>
                        </div>

                        <div className="timeline-icon">
                            <span className="dot-badge"></span>
                        </div>

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Get a Quote</h3>
                                        <p>To get started, our team will need the property address and contact information. We will send you a Staging Proposal and Quote that outlines the rooms that should be staged based on your budget and the needs of your listing. If you’re interested in learning about financing options, we recommend you visit our Pay at Close page to explore Zoom Casa home concierge services.</p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={history1} alt="about" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-block">
                        <div className="timeline-date">
                            <span>Step</span>
                            2
                            <sup>nd</sup>
                        </div>

                        <div className="timeline-icon">
                            <span className="dot-badge"></span>
                        </div>

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Preview the Property</h3>
                                        <p>After you approve the Staging Proposal, we will schedule a time to visit your property. When we preview a home, we like to walk through each room with the buyer’s perspective in mind to determine the aesthetic and feel. We will gather measurements and photos to help our designers select the perfect furniture and decor for each room. Our team can also make any additional suggestions for simple updates that may have a big impact on the sale price of your listing, such as painting, flooring, window treatments, or lighting. We have a highly-recommended vendor list that we’re also happy to share with you.</p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={history2} alt="about" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-block">
                        <div className="timeline-date">
                            <span>Step</span>
                            3
                            <sup>rd</sup>
                        </div>

                        <div className="timeline-icon">
                            <span className="dot-badge"></span>
                        </div>

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Design and Planning of Your Custom Stage</h3>
                                        <p>Our design team will meet at our 8,500 square foot warehouse and compose a plan for each individual room stage. Our expert designers have a pulse on what buyers are looking for and can customize each stage to fit the needs of the property. At Simply Staging, our diverse collection of furniture and decor allows us to select from a wide array of design styles.</p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={StagingAnalytics} alt="about" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-block">
                        <div className="timeline-date">
                            <span>Step</span>
                            4
                            <sup>th</sup>
                        </div>

                        <div className="timeline-icon">
                            <span className="dot-badge"></span>
                        </div>

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Staging Delivery and Set-Up</h3>
                                        <p>After the majority of our staging planning is done back at the warehouse, our next step is to bring these designs to life in your home. Each item is pulled intentionally which means we know exactly where the item will be placed before we arrive on the day of staging to ensure an efficient setup. You won’t have to lift a finger!</p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={StagingSetup} alt="about" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-block">
                        <div className="timeline-date">
                            <span>Step</span>
                            5
                            <sup>th</sup>
                        </div>

                        <div className="timeline-icon">
                            <span className="dot-badge"></span>
                        </div>

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Staging Removal</h3>
                                        <p>This is the best part for you, the seller! After your home has sold, we remove our staging inventory and leave the property in move-in condition. We take the most pride in this process as it is the result of the successful sale of your home or listing. Your success = our success.</p>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={history4} alt="about" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>
        </section>
    )
}

export default StagingProcess